'use client';

import { ErrorFallback } from '@kelvininc/shared-ui';
import { captureException } from '@sentry/nextjs';
import { useEffect } from 'react';

export const GlobalError = ({ error }: { error: Error }) => {
	useEffect(() => {
		if (error) {
			captureException(error);
		}
	}, [error]);

	return (
		<html>
			<body>
				<ErrorFallback />
			</body>
		</html>
	);
};

export default GlobalError;
